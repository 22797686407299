"use client";

import IconLink from "@/components/common/IconLink/IconLink";
import { AppIcons } from "@/components/global/icons/AppIcons";
import { Auth } from "@/lib/integration/auth/Auth";
import logIn = Auth.logIn;

type Props = {
  hidden?: boolean;
};
export default function LoginButton({ hidden }: Readonly<Props>) {
  return (
    <IconLink
      id="login"
      hidden={hidden}
      onClick={() => logIn()}
      icon={AppIcons.PowerOffIcon}
      showLoadingIndicatorOnClick
    />
  );
}
