import Image from "next/image";
import logo from "./DLRG-Logo.svg";
import "./DLRGLogo.css";

export default function DLRGLogo(props: {
  readonly variant: "small" | "large";
  readonly className?: string;
}): JSX.Element {
  const className: string = `${props.variant} ${props.className}`;
  return <Image className={className} src={logo} alt="DLRG-Logo" priority={true}/>;
}
