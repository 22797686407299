import MainNavigation from "@/components/global/layout/Navigation/MainNavigation";
import React from "react";
import { TFunction } from "i18next";

type Props = {
  lang: string,
  path: string,
  children?: React.ReactNode,
  t: TFunction<string, undefined>
};
export default function HeaderBase(props: Readonly<Props>) {
  return (
    <header>
      <MainNavigation {...props}>
        {props.children}
      </MainNavigation>
    </header>
  );
}