"use client";

import "./MainNavigation.css";

import DLRGLogo from "@/components/global/layout/DLRGLogo/DLRGLogo";
import { sanitize } from "@/components/global/layout/Navigation/SanitizeNavigation";
import mainItems from "@/navigation/main.json";
import Link from "next/link";
import React from "react";
import { Trans } from "react-i18next/TransWithoutContext";
import NavigationItem from "./NavigationItem";
import { TFunction } from "i18next";

type Props = {
  children?: React.ReactNode | React.ReactNode[],
  lang: string,
  path: string,
  t: TFunction<string, undefined>
};

export default function MainNavigation(props: Readonly<Props>) {
  const t = props.t;
  const items: NavigationItem[] = sanitize(mainItems as NavigationItem[]);

  const isActive = (item: NavigationItem): boolean => {
    try {
      const parts = props.path.split("/");
      if (parts.length < 3) {
        return false;
      }
      const moduleName = parts[2];
      return item.href.startsWith(`/${moduleName}`);
    } catch (Error) {
      console.warn("Error while checking active state of navigation item");
      console.warn(Error);
      return false;
    }
  }

  return (
    <nav className="navbar navbar-main navbar-expand-lg navbar-light d-flex main-navigation fixed-top">
      <div className="container">
        <Link className="navbar-brand" href={`/${props.lang}`}>
          <DLRGLogo variant="large" className="d-inline-block"/>
        </Link>
        <button
          className="navbar-toggler custom-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav navbar-nav-main me-auto mb-2 mb-lg-0">
            {items.map((item) => {
                const className: string = "nav-link" + (isActive(item) ? " active" : "");
                const inner: JSX.Element = item.disabled ?
                  <span className="nav-text" id={"main-navigation-" + item.key}><Trans i18nKey={item.label} t={t}></Trans>
                  </span> : <Link
                    className={className}
                    href={item.href}
                    target={item.target}
                    id={"main-navigation-" + item.key}
                  >
                    <Trans i18nKey={item.label} t={t}/>
                  </Link>;
                return (
                  <li className="nav-item" key={item.key}>
                    {inner}
                  </li>
                );
              }
            )}
          </ul>
          {props.children}
        </div>
      </div>
    </nav>
  );
}
