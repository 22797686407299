"use client";

import UserStatus from "@/components/global/layout/UserStatus/UserStatus";
import { UserProfileModel } from "../../helper/UserProfileModel";

type Props = {
  lang: string;
  selectOrganizationTooltip?: string;
  userProfile: UserProfileModel;
};
export default function UserStatusClient(props: Readonly<Props>) {
  return <UserStatus lang={props.lang} userProfile={props.userProfile} selectOrganizationTooltip={props.selectOrganizationTooltip}/>;
}
