"use client";

import IconLink from "@/components/common/IconLink/IconLink";
import { AppIcons } from "@/components/global/icons/AppIcons";
import { Auth } from "@/lib/integration/auth/Auth";
import logout = Auth.logOut;

interface LogoutButtonProps {
  hidden: boolean
}
export default function LogoutButton({ hidden }: LogoutButtonProps) {
  return (
    <IconLink
      id="logout"
      hidden={hidden}
      icon={AppIcons.PowerOffIcon}
      onClick={() => logout()}
      showLoadingIndicatorOnClick
    />
  );
}
