import { useEffect, useState } from "react";
import { OrganizationModel } from "./OrganizationModel";
import { OrganizationSelectionRepository } from "./OrganizationSelectionRepository";
import { SelectedOrganizationChangedEvent } from "./SelectedOrganizationChangedEvent";

type Props = {
  organizations: OrganizationModel[];
};
export default function useOrganizationSelectController(
  props: Readonly<Props>
) {
  const [selectedOrganization, setSelectedOrganization] = useState<
    OrganizationModel | undefined
  >();
  const [organizations, setOrganizations] = useState<OrganizationModel[]>([]);

  useEffect(() => {
    setOrganizations(props.organizations);
    if (props.organizations == undefined || props.organizations.length === 0) {
      selectOrganization(undefined);
    } else {
      const currentSelection =
        OrganizationSelectionRepository.getSelectedOrganization()?.id;
      const isValidOrganization = props.organizations.some(
        (organization) => organization.id === currentSelection
      );
      if (isValidOrganization) {
        selectOrganization(currentSelection);
      } else {
        selectOrganization(props.organizations[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organizations]);

  function selectOrganization(organizationId: string | undefined) {
    let organization: OrganizationModel | undefined = undefined;
    if (organizationId !== undefined) {
      organization = props.organizations.find(
        (organization) => organization.id === organizationId
      );
    }
    OrganizationSelectionRepository.saveSelectedOrganization(organization);
    setSelectedOrganization(organization);
    fireOrganizationSelectionChangedEvent();

  }

  function fireOrganizationSelectionChangedEvent() {
    if (typeof window !== undefined) {
      window.dispatchEvent(
        new SelectedOrganizationChangedEvent(selectedOrganization?.id)
      );
    }
  }

  return {
    organizations,
    selectedOrganizationId: selectedOrganization?.id,
    selectedOrganizationName: selectedOrganization?.name,
    onSelectionChange: selectOrganization,
  };
}
