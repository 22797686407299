"use client";

import IconLink from "@/components/common/IconLink/IconLink";
import { AppIcons } from "@/components/global/icons/AppIcons";
import HeaderBase from "@/components/global/layout/Header/HeaderBase";
import UserStatusClient from "@/components/global/layout/UserStatus/UserStatusClient";
import { useTranslation } from "@/i18n/client";
import { usePathname } from "next/navigation";
import { UserProfileModel } from "../../helper/UserProfileModel";
import "./Header.css";

type Props = {
  lang: string;
  userProfile: UserProfileModel;
};
export default function HeaderClient(props: Readonly<Props>) {
  const pathname = usePathname();
  const { t } = useTranslation(props.lang, "header");

  return <HeaderBase {...props} t={t} path={pathname}>
    <IconLink href={`/${props.lang}/help`} icon={AppIcons.CircleQuestionIcon} id="help"/>
    <UserStatusClient
      lang={props.lang}
      userProfile={props.userProfile}
    />
  </HeaderBase>;
}
