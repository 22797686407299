import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
} from "next";
import { NextAuthOptions, Session, getServerSession } from "next-auth";
import { JWT } from "next-auth/jwt";
import Keycloak from "next-auth/providers/keycloak";
import { AuthConfig } from "./AuthConfig";
import { JwtUtil } from "./JwtUtil";

declare module "next-auth" {
  interface Session {
    error?: "RefreshAccessTokenError";
    access_token?: string;
    expires?: string;
    expires_at?: number;
  }
}

declare module "next-auth/jwt" {
  interface JWT {
    id_token: string;
    access_token: string;
    expires_at: number; // This is an Unix-Timestamp - to get the milliseconds since 1970 multiply with 1000!!!
    refresh_token: string;
    error?: "RefreshAccessTokenError";
    session?: Session;
  }
}

export namespace NextAuth {
  export const config: NextAuthOptions = {
    providers: [
      Keycloak({
        clientId: AuthConfig.OIDC_CLIENT_ID,
        clientSecret: AuthConfig.OIDC_CLIENT_SECRET,
        issuer: AuthConfig.OIDC_ISSUER,
      }),
    ],
    callbacks: {
      async jwt({
        token,
        account,
        trigger,
      }: {
        token: JWT;
        account: any;
        trigger?: "signIn" | "signUp" | "update";
      }): Promise<JWT> {
        if (account) {
          // Save the access token and refresh token in the JWT on the initial login, as well as the user details
          return {
            access_token: account.access_token,
            expires_at: account.expires_at,
            refresh_token: account.refresh_token,
            id_token: account.id_token,
            user: token,
          };
        }

        const tokenExpiresAt = token.expires_at * 1000;

        const isTokenNotExpired = Date.now() < tokenExpiresAt;
        if (isTokenNotExpired) {
          // If the access token has not expired yet, return it
          return token;
        }

        if (!token.refresh_token) throw new Error("Missing refresh token");
        // If the access token has expired, try to refresh it
        try {
          const tokens = await JwtUtil.refreshTokenRequest(token);
          const expirationTime = Math.floor(
            Date.now() / 1000 + tokens.expires_in
          );
          return {
            ...token, // Keep the previous token properties
            access_token: tokens.access_token,
            expires_at: expirationTime,
            // Fall back to old refresh token, but note that
            // many providers may only allow using a refresh token once.
            refresh_token: tokens.refresh_token ?? token.refresh_token,
          };
        } catch (error) {
          console.log(error);
          return token;
        }
      },
      async session({
        session,
        token,
      }: {
        session: Session;
        token: JWT;
      }): Promise<Session> {
        return {
          ...session,
          ...token,
        };
      },
    },
    events: {
      async signOut({ session, token }: { session: Session; token: JWT }) {
        const logOutUrl = new URL(AuthConfig.LOGOUT_URL);
        logOutUrl.searchParams.set("id_token_hint", token.id_token);
        await fetch(logOutUrl);
      },
    },
  };

  export function getServerSessionWithAuthentication(
    ...args:
      | [GetServerSidePropsContext["req"], GetServerSidePropsContext["res"]]
      | [NextApiRequest, NextApiResponse]
      | []
  ):Promise<Session|null> {
    return getServerSession(...args, config);
  }
}
