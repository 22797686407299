import IconLink from "@/components/common/IconLink/IconLink";
import { AppIcons } from "@/components/global/icons/AppIcons";
import LoginButton from "@/components/global/layout/UserStatus/LoginButton";
import OrganizationSelect from "../OrganizationSelect/OrganizationSelect";
import LogoutButton from "./LogoutButton";

import { UserProfileModel } from "../../helper/UserProfileModel";
import "./UserStatus.css";

type Props = {
  lang: string;
  selectOrganizationTooltip?: string;
  userProfile: UserProfileModel;
};
export default function UserStatus(props: Readonly<Props>) {
  const isAdministrationIconVisible = props.userProfile.isGlobalAdmin;
  const isOrganizationIconVisible = props.userProfile.organizations.length > 0;
  const isProfileIconVisible = props.userProfile.isLoggedIn;
  const isLoginButtonVisible = !props.userProfile.isLoggedIn;
  const isLogoutButtonVisible = props.userProfile.isLoggedIn;

  return (
    <div className="loginStatus">
      <IconLink
        href={`/${props.lang}/administration`}
        icon={AppIcons.Administration}
        id="permissions-link"
        hidden={!isAdministrationIconVisible}
      />
      <IconLink
        href={`/${props.lang}/organizations/current`}
        icon={AppIcons.Organization}
        id="permissions-link"
        hidden={!isOrganizationIconVisible}
      />
      <OrganizationSelect
        hidden={!isOrganizationIconVisible}
        organizations={props.userProfile.organizations}
        tooltip={props.selectOrganizationTooltip}
      />
      <IconLink
        icon={AppIcons.UserIcon}
        href="/profile/me"
        id="profile"
        hidden={!isProfileIconVisible}
      />
      <LogoutButton hidden={!isLogoutButtonVisible}/>
      <LoginButton hidden={!isLoginButtonVisible}/>
    </div>
  );
}
