"use client";
import { OrganizationModel } from "./OrganizationModel";
import "./OrganizationSelect.css";
import useOrganizationSelectController from "./useOrganizationSelectController";

type Props = {
  organizations: OrganizationModel[];
  tooltip?: string;
  hidden?: boolean;
};

function formatName(name: string) {
  if (name.startsWith("Ortsgruppe")) {
    return name.replace("Ortsgruppe", "OG");
  }
  if (name.startsWith("Landesverband")) {
    return name.replace("Landesverband", "LV");
  }
  if (name.startsWith("Bezirk")) {
    return name.replace("Bezirk", "BZ");
  }
  if (name.startsWith("Kreisverband")) {
    return name.replace("Kreisverband", "KV");
  }
  return name;
}

export default function OrganizationSelect(props: Readonly<Props>) {
  const controller = useOrganizationSelectController({
    organizations: props.organizations,
  });
  const organizations = controller.organizations || [];

  return (
    <form
      className="form-inline my-2 my-lg-0 organization-form"
      hidden={props.hidden}
    >
      <select
        className="form-select"
        id="select-organization"
        value={controller.selectedOrganizationId}
        onChange={(event) => controller.onSelectionChange(event.target.value)
        }
      >
        {organizations.sort((o1, o2) => o1.name.localeCompare(o2.name)).map((organization) => (
          <option key={organization.id} value={organization.id}>
            {formatName(organization.name)}
          </option>
        ))}
      </select>
    </form>
  )
    ;
}
