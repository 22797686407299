import NavigationItem from "@/components/global/layout/Navigation/NavigationItem";

export function sanitize(
  items: NavigationItem[],
): NavigationItem[] {
  try {
    return items.map((i: NavigationItem) => {
      const key: string = i.key || i.label.toLowerCase();
      let href: string = i.href || "/" + key;
      if (href.startsWith("$")) {
        const env: string | undefined =
            process.env["NEXT_PUBLIC_" + href.substring(1)];
        if (env && env.length > 0) {
          href = env;
        }
      }
      const target: "_blank" | "_self" = i.target || "_self";
      const disabled: boolean = i.disabled || false;
      return {
        label: i.label,
        key: key,
        href: href,
        target: target,
        disabled: disabled,
      } as NavigationItem;
    });
  } catch (Error) {
    console.warn("Error while sanitizing navigation items");
    console.warn(Error);
    return items;
  }
}
