import { JWT } from "next-auth/jwt";
import { AuthConfig } from "./AuthConfig";

export namespace JwtUtil {
  export async function refreshTokenRequest(token: JWT): Promise<any> {
    const response = await fetch(AuthConfig.TOKEN_ENDPOINT, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        client_id: AuthConfig.OIDC_CLIENT_ID,
        client_secret: AuthConfig.OIDC_CLIENT_SECRET,
        grant_type: "refresh_token",
        refresh_token: token.refresh_token,
      }),
      method: "POST",
    });
    return await response.json();
  }
}
